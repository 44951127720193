<template>
  <div class="home">
    <div class="canvas-container" ref="screenDom"></div>
    <div class="header">
      <div class="logoBox">
        <div class="logo"></div>
        <div class="name">
          <div class="h1">RDAG</div>
          <div class="tips">DAG GameFi</div>
        </div>
      </div>
      <div class="menu">
        <a href="https://github.com/rdagcoin-org/wallet/releases" class="menuItem">Wallets</a>
        <a href="https://pool.rdagcoin.org" class="menuItem">Mining</a>
        <a href="https://explorer.rdagcoin.org" class="menuItem">Explorer</a>
        <a href="./white_book.pdf" class="menuItem"> Whitepaper </a>
      </div>
    </div>
    <div class="pages" ref="pages">
      <div class="page">
        <h2 class="title">RDAG: Innovating Blockchain for GameFi</h2>
        <p class="contentText">
          By leveraging virtual blocks and Directed Acyclic Graph (DAG) technology, RDAG delivers rapid transaction confirmations, high
          network throughput, and robust security.
        </p>
        <br />
        <Card :color="['#ff0058', '#03a9f4']" width="460px">
          <div class="cardContent">
            <p>Mainnet Coin: RDAG Coin</p>
            <p>Total Supply: 1.8447e+19 RDAG</p>
            <p>Block Time: 1 minute</p>
            <p>Virtual Block Time: 100 blocks</p>
            <p>Halving Time: 1 year</p>
            <p>Initial Block Reward: 17.792 trillion RDAG</p>
          </div>
        </Card>
        <br />
        <span>GameFi / Virtual Blocks / DAG / High Throughput / Low Latency / Parallel Processing / Security </span>
        <br />

        <a href="https://github.com/rdagcoin-org">
          <div class="btn" style="--clr: #822155; width: 170px">
            <div>
              GitHub
              <el-icon><DArrowRight /></el-icon>
            </div>
            <i class="icon"></i>
          </div>
        </a>
      </div>
      <div class="page">
        <h2 class="title">OUR WALLET</h2>
        <div style="display: flex">
          <Card :color="['#FF9DE4', '#03a9f4']">
            <div class="cardContent">
              <div class="cardTitle">CLI</div>
              <p>Wallet with integrated node, slow to synchronize the first time and need all blockchain.</p>
              <p>
                This wallet is perfect for users who need comprehensive access to the blockchain and are comfortable using command-line
                tools.
              </p>
              <p>Support: Windows, Linux</p>
              <a href="https://github.com/rdagcoin-org/wallet/releases">
                <div class="btn" style="--clr: #4dff03">
                  <div>
                    Download
                    <el-icon><DArrowRight /></el-icon>
                  </div>
                  <i class="icon"></i>
                </div>
              </a>
            </div>
          </Card>
          <Card :color="['#FFC328', '#03a9f4']" style="margin-left: 60px">
            <div class="cardContent">
              <div class="cardTitle">Electrum</div>
              <p>
                Fast and light wallet. It uses external servers to validate transactions and this makes it unnecessary to download the
                blockchain.
              </p>
              <p>Support: Windows, Linux, MacOS</p>
              <p>under development</p>
            </div>
          </Card>

          <Card :color="['#FF6C6C', '#03a9f4']" style="margin-left: 60px">
            <div class="cardContent">
              <div class="cardTitle">Web</div>
              <p>
                The wallet used in the browser allows for convenient payment and receiving without the need to download any software. You
                can export the keys and import them into other wallets.
              </p>
              <p>Support: Browsers for computers and mobile phones.</p>
              <p>under development</p>
            </div>
          </Card>
        </div>
      </div>
      <div class="page" style="padding-left: 0; margin: 0 auto; align-items: center">
        <h2 class="title">ROADMAP</h2>

        <div style="display: flex; align-items: center">
          <el-icon
            style="font-size: 40px; cursor: pointer; position: relative; top: -10px; margin-right: 20px"
            @click="changeRoadmapYear(-1)"
            ><DArrowLeft
          /></el-icon>
          <h3 class="title">{{ roadmap_years[roadmap_index] }}</h3>
          <el-icon style="font-size: 40px; cursor: pointer; position: relative; top: -10px; margin-left: 20px" @click="changeRoadmapYear(1)"
            ><DArrowRight
          /></el-icon>
        </div>

        <transition name="el-zoom-in-center" mode="out-in">
          <div style="display: flex" v-if="roadmap_index === 0">
            <Card :color="['#4DEF8E', '#03a9f4']" style="display: flex" width="500px">
              <div class="cardContent">
                <div class="cardTitle">PHASE1</div>
                <p>Core Code Development</p>

                <div class="sm">
                  <p>Design and implement the core blockchain architecture.</p>
                  <p>Develop and test the consensus algorithm.</p>
                  <p>Integrate the Directed Acyclic Graph (DAG) structure.</p>
                  <p>Implement the virtual block generation mechanism.</p>
                </div>

                <p>Multi-Platform Wallet Development</p>

                <div class="sm">
                  <p>Develop command-line wallets for various operating systems</p>
                  <p>Create user-friendly GUI wallets for desktop and mobile platforms.</p>
                  <p>Ensure synchronization with the full blockchain.</p>
                </div>
              </div>
            </Card>

            <Card :color="['#FFEB3A', '#03a9f4']" style="margin-left: 30px" width="500px">
              <div class="cardContent">
                <div class="cardTitle">PHASE2</div>
                <p>Smart Contract Functionality Enhancement and Testing</p>

                <div class="sm">
                  <p>Develop and integrate smart contract functionality.</p>
                  <p>Conduct thorough testing of smart contract deployment and execution.</p>
                  <p>Implement security audits and bug bounty programs to ensure robustness.</p>
                </div>

                <p>Seek GameFi Partnerships</p>

                <div class="sm">
                  <p>Identify and approach potential GameFi partners.</p>
                  <p>Develop strategic partnerships with blockchain-based game developers.</p>
                  <p>Provide integration support and co-marketing opportunities.</p>
                </div>
              </div>
            </Card>
          </div>

          <div style="display: flex" v-else-if="roadmap_index === 1">
            <Card :color="['#4D6AD0', '#03a9f4']" width="500px">
              <div class="cardContent">
                <div class="cardTitle">PHASE1</div>
                <p>Community Building</p>

                <div class="sm">
                  <p>Launch official website and social media channels.</p>
                  <p>Establish and grow a vibrant community through forums, AMAs, and developer engagement.</p>
                  <p>Initiate educational content and documentation to onboard new users and developers.</p>
                  <p>Organize hackathons and community events to foster engagement and innovation.</p>
                </div>

                <p>Ecosystem Expansion</p>

                <div class="sm">
                  <p>Expand support for decentralized applications (DApps) across various industries.</p>
                  <p>Collaborate with IoT companies for integrating RDAG into their ecosystems.</p>
                  <p>Promote RDAG for supply chain management solutions to enhance transparency and trust.</p>
                </div>
              </div>
            </Card>

            <Card :color="['#FF9D7E', '#03a9f4']" style="margin-left: 30px" width="500px">
              <div class="cardContent">
                <div class="cardTitle">PHASE2</div>
                <p>Network Scalability and Performance Optimization</p>

                <div class="sm">
                  <p>Implement performance optimizations to handle increased transaction volumes.</p>
                  <p>Explore and integrate layer-2 scaling solutions if necessary.</p>
                  <p>Continuously monitor and improve network security.</p>
                </div>

                <p>Regulatory Compliance and Global Outreac</p>

                <div class="sm">
                  <p>Ensure compliance with international regulatory standards.</p>
                  <p>Establish partnerships with global financial institutions and payment processors.</p>
                  <p>Expand market presence through global marketing campaigns and localization efforts.</p>
                </div>
              </div>
            </Card>
          </div>
          <div style="display: flex" v-else-if="roadmap_index === 2">
            <Card :color="['#24CFC5', '#03a9f4']" width="600px">
              <div class="cardContent">
                <p>Ongoing Community Engagement and Development</p>

                <div style="font-size: 12px; line-height: 1.15">
                  <p>Regular updates and communication with the community about progress and developments.</p>
                  <p>Foster continuous feedback loops with developers and users for iterative improvements.</p>
                  <p>Host regular events, webinars, and collaborative projects to maintain high community engagement.</p>
                </div>
              </div>
            </Card>
          </div>
        </transition>
      </div>

      <div class="page" style="padding-left: 0; margin: 0 auto; align-items: center">
        <Card :color="['#FF5EEF', '#03a9f4']" style="display: flex" width="820px">
          <div class="cardContent">
            <div class="cardTitle">RDAG</div>
            <p>
              At RDAG, our mission is to revolutionize the blockchain landscape by leveraging advanced technologies to solve the inherent
              challenges of decentralization, security, and high throughput. We aim to create a robust and versatile platform that empowers
              developers and users alike, enabling a wide range of applications from decentralized finance to blockchain-based gaming.
            </p>

            <div class="icon">
              <div class="discord">
                <a href="https://discord.com/invite/99RhNtC6fg">
                <el-image src="/assets/discord.svg" fit="cover" />
                </a>
              </div>
              <div class="twitte">
                <a href="https://x.com/OrgRdag">
                  <svg
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    class="r-4qtqp9 r-yyyyoo r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-lrsllp r-1nao33i r-16y2uox r-8kz0gk"
                    width="200"
                    height="200"
                  >
                    <g>
                      <path
                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </svg>
                </a>
              </div>

              <div class="github">
                <a href="https://github.com/rdagcoin-org">
                  <el-image src="/assets/github.svg" fit="cover" />
                </a>
              </div>
            </div>

            <p><a href="mailto:org@rdagcoin.org">org@rdagcoin.org</a></p>
            <p>© 2024 RDAG. All rights reserved.</p>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import * as THREE from 'three'
import { ref, onMounted } from 'vue'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { gsap } from 'gsap'
import Card from '@/com/card.vue'

let screenDom = ref(null)
let pages = ref(null)
onMounted(() => {
  let scene = new THREE.Scene()
  let camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 100000)

  camera.position.set(0, 0, 10)
  let renderer = new THREE.WebGLRenderer({ antialias: true })
  renderer.setSize(window.innerWidth, window.innerHeight)
  screenDom.value.appendChild(renderer.domElement)

  let url = './assets/25s.jpg'
  let envTexture = new THREE.TextureLoader().load(url)
  envTexture.mapping = THREE.EquirectangularReflectionMapping
  scene.background = envTexture
  scene.environment = envTexture

  function render() {
    requestAnimationFrame(render)
    renderer.render(scene, camera)
  }
  render()

  let light = new THREE.DirectionalLight(0xffffff, 1)
  light.position.set(0, 0, 1)
  scene.add(light)
  let light2 = new THREE.DirectionalLight(0xffffff, 0.5)
  light2.position.set(0, 0, -1)
  scene.add(light2)
  let light3 = new THREE.AmbientLight(0xffffff, 0.5)
  light3.position.set(-1, 1, 1)
  scene.add(light3)

  let dracoLoader = new DRACOLoader()
  dracoLoader.setDecoderPath('./draco/gltf/')
  dracoLoader.setDecoderConfig({ type: 'js' })
  let loader = new GLTFLoader()
  loader.setDRACOLoader(dracoLoader)
  loader.load('./model/ybbb2.glb', (gltf) => {
    gltf.scene.scale.set(80, 80, 80)
    gltf.scene.position.set(3, 0, 0)
    const model = gltf.scene
    scene.add(model)

    window.addEventListener('mousemove', (e) => {
      let x = (e.clientX / window.innerWidth) * 2 - 1
      let y = (e.clientY / window.innerHeight) * 2 - 1

      let timeline = gsap.timeline()
      timeline.to(gltf.scene.rotation, {
        duration: 0.5,
        x: y,
        y: x,
        duration: 1
      })
    })
  })

  loader.load('./model/22.glb', (gltf) => {
    gltf.scene.scale.set(50, 50, 50)
    gltf.scene.position.set(3, -8, 0)
    scene.add(gltf.scene)

    window.addEventListener('mousemove', (e) => {
      let x = (e.clientX / window.innerWidth) * 2 - 1
      let y = (e.clientY / window.innerHeight) * 2 - 1

      let timeline = gsap.timeline()
      timeline.to(gltf.scene.rotation, {
        duration: 0.5,
        x: y,
        y: x,
        duration: 1
      })
    })
  })

  loader.load('./model/xq6.glb', (gltf) => {
    gltf.scene.scale.set(0.1, 0.1, 0.1)
    gltf.scene.position.set(3, -16, 0)
    scene.add(gltf.scene)

    window.addEventListener('mousemove', (e) => {
      let x = (e.clientX / window.innerWidth) * 2 - 1
      let y = (e.clientY / window.innerHeight) * 2 - 1

      let timeline = gsap.timeline()
      timeline.to(gltf.scene.rotation, {
        duration: 0.5,
        x: y,
        y: x,
        duration: 1
      })
    })
  })

  loader.load('./model/xz.glb', (gltf) => {
    gltf.scene.scale.set(0.1, 0.1, 0.1)
    gltf.scene.position.set(3, -24, 0)
    scene.add(gltf.scene)

    window.addEventListener('mousemove', (e) => {
      let x = (e.clientX / window.innerWidth) * 2 - 1
      let y = (e.clientY / window.innerHeight) * 2 - 1

      let timeline = gsap.timeline()
      timeline.to(gltf.scene.rotation, {
        duration: 0.5,
        x: y,
        y: x,
        duration: 1
      })
    })
  })

  let page = 0
  let timeline2 = gsap.timeline()
  window.addEventListener('mousewheel', (e) => {
    if (e.wheelDelta < 0) {
      page++
      if (page > 3) {
        page = 3
      }
    }
    if (e.wheelDelta > 0) {
      page--
      if (page < 0) {
        page = 0
      }
    }
    if (!timeline2.isActive()) {
      timeline2.to(camera.position, {
        duration: 0.5,

        y: page * -8,
        duration: 1
      })
      gsap.to(pages.value, {
        duration: 1,
        y: -page * window.innerHeight,
        duration: 1
      })
    }
  })

  loader.load('./model/moon.glb', (gltf) => {
    let moon = gltf.scene.children[0]
    for (let j = 0; j < 10; j++) {
      let moonInstance = new THREE.InstancedMesh(moon.geometry, moon.material, 100)

      // scene.add(moon);
      for (let i = 0; i < 100; i++) {
        let x = Math.random() * 1000 - 500
        let y = Math.random() * 1000 - 500
        let z = Math.random() * 1000 - 500

        let matrix = new THREE.Matrix4()
        let size = Math.random() * 20 - 8
        matrix.makeScale(size, size, size)
        matrix.makeTranslation(x, y, z)
        moonInstance.setMatrixAt(i, matrix)
      }

      gsap.to(moonInstance.position, {
        duration: Math.random() * 10 + 2,
        z: -1000,
        ease: 'linear',
        repeat: -1
      })
      scene.add(moonInstance)
    }
  })
})

const roadmap_index = ref(0)
const roadmap_years = [2024, 2025, '']
const changeRoadmapYear = (index) => {
  if (roadmap_index.value === 0 && index < 0) return
  if (roadmap_index.value === 2 && index > 0) return
  roadmap_index.value += index
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
}
.canvas-container {
  width: 100vw;
  height: 100vh;
}
.home {
  width: 100vw;
  height: 100vh;
  transform-origin: 0 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;

  .logoBox {
    display: flex;
    color: #fff;
    align-items: center;
    .logo {
      height: 120px;
      width: 120px;
      background-image: url('./assets/rdagcoin_logo.png');
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .name {
      .h1 {
        font-size: 26px;
        font-weight: bold;
      }
      .tips {
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

.canvas-container {
  width: 100%;
  height: 100%;
}
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  z-index: 999;
}
.menuItem {
  padding: 0 15px;
  text-decoration: none;
  color: #fff;
  font-weight: 900;
  font-size: 15px;
  cursor: pointer;
  transition: 0.23s;
  &:hover {
    color: #00ff99;
  }
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/loading.jpg);
  background-size: cover;
  filter: blur(50px);
  z-index: 100;
}
.progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  > img {
    padding: 0 15px;
  }
}

.pages {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
}
.pages .page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  padding: 15%;
  box-sizing: border-box;
}
.pages .page .title {
  font-size: 50px;
  font-weight: 900;
  margin-bottom: 20px;
}
.pages .page .contentText {
  font-size: 25px;
}

.btn {
  position: relative;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 1.5em;
  letter-spacing: 0.1em;
  font-weight: 400;
  padding: 10px 30px;
  transition: 0.5s;
  cursor: pointer;
}

.btn:hover {
  letter-spacing: 0.25em;
  color: var(--clr);
  box-shadow: 0 0 35px var(--clr);
}

.btn::before {
  content: '';
  position: absolute;
  inset: 2px;
  background: transparent;
}

.btn div {
  position: relative;
  z-index: 1;
  white-space: nowrap;
}

.btn i.icon {
  position: absolute;
  inset: 0;
  display: block;
  opacity: 0.78;
}

.btn i.icon::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 100%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background: #27282c;
  border: 2px solid var(--clr);
  transition: 0.5s;
  opacity: 0;
}

.btn:hover i.icon::before {
  left: 0%;
  transform: translateX(-50%) rotate(45deg);
  box-shadow: 40px 39px var(--clr);
  opacity: 1;
}

.btn i.icon::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background: #27282c;
  border: 2px solid var(--clr);
  transition: 0.5s;
  opacity: 0;
}

.btn:hover i.icon::after {
  left: 100%;
  transform: translateX(-50%) rotate(-45deg);
  box-shadow: 38px -39px var(--clr);
  opacity: 1;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  flex-wrap: wrap;
}

// .card:nth-child(2):after,
// .card:nth-child(2):before {
//   background: linear-gradient(315deg, #03a9f4, #ff0058);
// }

// .container .box:nth-child(3):after,
// .container .box:nth-child(3):before {
//   background: linear-gradient(315deg, #4dff03, #00d0ff);
// }

.cardContent {
  a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
  }
  .cardTitle {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    margin-bottom: 14px;
  }
  .sm p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .icon {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    svg,
    img,
    .el-img {
      width: 100px;
      height: 100px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scaleX(-1);
      }
    }
  }
}
</style>
